import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useApi, identityApiRef, configApiRef } from '@backstage/core-plugin-api'
import { Button } from '@material-ui/core';

const handleResponse = async (res:Response) => {
  if (res.status >= 300){
    throw new Error(res.statusText)
  }
  alert(JSON.stringify(await res.json()))
}

const ExampleContent = () => {
  const entity = useEntity();
  const identityApi = useApi(identityApiRef);
  const config = useApi(configApiRef)

  const myApiEndpoint = `${config.getString("backend.baseUrl")}/api/my-api`

  const onFetchPublic = async () => {
    try {
      const res = await fetch(`${myApiEndpoint}/health`);
      await handleResponse(res)
    } catch (ex) {
      alert(ex);
    }
  };

  const onFetchProtected = async () => {
    try {
      const {token} = await identityApi.getCredentials()
      const res = await fetch(`${myApiEndpoint}/protected`, {
        headers: new Headers({
          'Authorization': `Bearer ${token}`
        })
      });
      await handleResponse(res)
    } catch (ex) {
      alert(ex);
    }
  };

  return (
    <div>
      <h1>Hello from The Avengers</h1>
      <pre>{JSON.stringify(entity.entity.metadata, null, 4)}</pre>
      <Button variant="contained" color="primary" onClick={onFetchPublic}>
        Fetch public
      </Button>
      &nbsp;
      <Button variant="contained" color="primary" onClick={onFetchProtected}>
        Fetch protected
      </Button>
    </div>
  );
};

export { ExampleContent };
